import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faCircleNotch as freeFasFaCircleNotch,
  faComment as freeFasFaComment,
  faComments as freeFasFaComments,
  faFilter as freeFasFaFilter,
  faPlus as freeFasFaPlus,
  faMinus as freeFasFaMinus,
  faPen as freeFasFaPen,
  faTrash as freeFasFaTrash,
  faTrashArrowUp as freeFasFaTrashArrowUp,
  faMagnifyingGlass as freeFasFaMagnifyingGlass,
  faXmark as freeFasFaXmark,
  faCircleXmark as freeFasFaCircleXmark,
  faCheck as freeFasFaCheck,
  faCircleCheck as freeFasFaCircleCheck,
  faFileLines as freeFasFaFileLines,
  faPerson as freeFasFaPerson,
  faPersonDress as freeFasFaPersonDress,
  faEuroSign as freeFasFaEuroSign,
  faUsers as freeFasFaUsers,
  faUsersSlash as freeFasFaUsersSlash,
  faUserShield as freeFasFaUserShield,
  faUserLock as freeFasFaUserLock,
  faUserXmark as freeFasFaUserXmark,
  faUserGear as freeFasFaUserGear,
  faUserSlash as freeFasFaUserSlash,
  faUserCheck as freeFasFaUserCheck,
  faCircle as freeFasFaCircle,
  faUpDown as freeFasFaUpDown,
  faLeftRight as freeFasFaLeftRight,
  faArrowLeft as freeFasFaArrowLeft,
  faArrowRight as freeFasFaArrowRight,
  faArrowsRotate as freeFasFaArrowsRotate,
  faChevronUp as freeFasFaChevronUp,
  faChevronDown as freeFasFaChevronDown,
  faRotateLeft as freeFasFaRotateLeft,
  faRotateRight as freeFasFaRotateRight,
  faSquare as freeFasFaSquare,
  faSquarePlus as freeFasFaSquarePlus,
  faSquareMinus as freeFasFaSquareMinus,
  faSquareCheck as freeFasFaSquareCheck,
  faCircleQuestion as freeFasFaCircleQuestion,
  faGift as freeFasFaGift,
  faTriangleExclamation as freeFasFaTriangleExclamation,
  faDiagramProject as freeFasFaDiagramProject,
  faGear as freeFasFaGear,
  faPrint as freeFasFaPrint,
  faPeopleArrows as freeFasFaPeopleArrows,
  faPaperPlane as freeFasFaPaperPlane,
  faFaceSmile as freeFasFaFaceSmile,
  faPaperclip as freeFasFaPaperclip,
  faExternalLinkAlt as freeFasFaExternalLinkAlt,
  faNoteSticky as freeFasFaNoteSticky,
  faEye as freeFasFaEye,
  faEyeSlash as freeFasFaEyeSlash,
  faLocationDot as freeFasFaLocationDot,
  faWrench as freeFasFaWrench,
  faBan as freeFasFaBan,
  faBars as freeFasFaBars,
  faStar as freeFasFaStar,
  faTableList as freeFasFaTableList,
  faTableCells as freeFasFaTableCells,
  faEllipsisVertical as freeFasFaEllipsisVertical,
  faSort as freeFasFaSort,
  faArrowDownAZ as freeFasFaArrowDownAZ,
  faArrowUpZA as freeFasFaArrowUpZA,
  faBookOpen as freeFasFaBookOpen,
  faCoins as freeFasFaCoins,
  faBug as freeFasFaBug,
  faDownload as freeFasFaDownload,
  faUpload as freeFasFaUpload,
  faSpinner as freeFasFaSpinner
} from '@fortawesome/free-solid-svg-icons'

library.add(
  freeFasFaCircleNotch,
  freeFasFaComment,
  freeFasFaComments,
  freeFasFaFilter,
  freeFasFaPlus,
  freeFasFaMinus,
  freeFasFaPen,
  freeFasFaTrash,
  freeFasFaTrashArrowUp,
  freeFasFaMagnifyingGlass,
  freeFasFaXmark,
  freeFasFaCircleXmark,
  freeFasFaCheck,
  freeFasFaCircleCheck,
  freeFasFaFileLines,
  freeFasFaPerson,
  freeFasFaPersonDress,
  freeFasFaEuroSign,
  freeFasFaUsers,
  freeFasFaUsersSlash,
  freeFasFaUserShield,
  freeFasFaUserLock,
  freeFasFaUserXmark,
  freeFasFaUserGear,
  freeFasFaUserSlash,
  freeFasFaUserCheck,
  freeFasFaCircle,
  freeFasFaUpDown,
  freeFasFaLeftRight,
  freeFasFaArrowLeft,
  freeFasFaArrowRight,
  freeFasFaArrowsRotate,
  freeFasFaChevronUp,
  freeFasFaChevronDown,
  freeFasFaRotateLeft,
  freeFasFaRotateRight,
  freeFasFaSquare,
  freeFasFaSquarePlus,
  freeFasFaSquareMinus,
  freeFasFaSquareCheck,
  freeFasFaCircleQuestion,
  freeFasFaGift,
  freeFasFaTriangleExclamation,
  freeFasFaDiagramProject,
  freeFasFaGear,
  freeFasFaPrint,
  freeFasFaPeopleArrows,
  freeFasFaPaperPlane,
  freeFasFaFaceSmile,
  freeFasFaPaperclip,
  freeFasFaExternalLinkAlt,
  freeFasFaNoteSticky,
  freeFasFaEye,
  freeFasFaEyeSlash,
  freeFasFaLocationDot,
  freeFasFaWrench,
  freeFasFaBan,
  freeFasFaBars,
  freeFasFaStar,
  freeFasFaTableList,
  freeFasFaTableCells,
  freeFasFaEllipsisVertical,
  freeFasFaSort,
  freeFasFaArrowDownAZ,
  freeFasFaArrowUpZA,
  freeFasFaBookOpen,
  freeFasFaCoins,
  freeFasFaBug,
  freeFasFaDownload,
  freeFasFaUpload,
  freeFasFaSpinner
)

config.autoAddCss = false

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText)
